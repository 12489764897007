<template>
  <el-row :gutter="20">
    <el-col
        :xs="24"
        :sm="24"
        :md="24"
        :lg="12"
        :xl="12"
    >
      <div class="heading">
        <h1 v-if="realtorid">
          Domains
        </h1>
      </div>
    </el-col>
    <el-col
        :xs="6"
        :sm="6"
        :md="6"
        :lg="12"
        :xl="12"
    >
      <div class="topsearch">
        <el-button
            type="success"
            @click="submitForm('ruleForm')"
        >
          Save
        </el-button>
      </div>
    </el-col>
  </el-row>
  <el-row :gutter="20">
    <el-col
        :xs="24"
        :sm="24"
        :md="24"
        :lg="24"
        :xl="24"
    >
      <div v-if="showAlertAdded">
        <el-alert
            v-if="!realtorid"
            title="Realtor Added Successfully"
            type="success"
            effect="dark"
        />
        <el-alert
            v-if="realtorid"
            title="Realtor Updated Successfully"
            type="success"
            effect="dark"
        />
      </div>
    </el-col>
  </el-row>

  <el-form
      ref="ruleForm"
      :model="data"
      status-icon
      :rules="rules"
      label-width="180px"
      class=" login-form"
      :label-position="labelPosition"
  >
    <div class="panel panel-default">
      <div
          class="panel-title"
          style="padding-bottom: 25px;margin-bottom: 20px;"
      >
        <i class="fa fa-bars"/> Domain Settings
      </div>
      <el-row :gutter="20">
        <el-col xs:="24" :sm="24" :md="24" :lg="12" :xl="12">
          <el-form-item label="Website URL">
            <el-input
                readonly
                v-model="data.config_url"
                type="text"
                autocomplete="off"
            />
          </el-form-item>
        </el-col>
        <el-col xs:="24" :sm="24" :md="24" :lg="12" :xl="12">

        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col xs:="24" :sm="24" :md="24" :lg="12" :xl="12">
          <el-form-item label="Website Custom Domain">
            <el-input
                :readonly="domainVerified.config_custom_url1"
                :disabled="domainVerified.config_custom_url1"
                v-model="data.config_custom_url1"
                type="text"
                placeholder="www.mywebsite.com"
                autocomplete="off"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20" style="padding-top: 20px;padding-bottom: 20px;" v-if="data.config_custom_url1">
        <el-col xs:="24" :sm="24" :md="24" :lg="24" :xl="24" class="align-left" style="text-align: left">
          <span style="font-weight: bold">NOTE</span>:<br> To activate your custom domain, kindly establish a "CNAME" record within your domain's DNS settings as indicated below. Subsequently, proceed to click "Verify Now." <br>Upon successful verification, your personalized vanity domain will be fully operational.
        </el-col>
      </el-row>
      <el-row :gutter="20" v-if="data.config_custom_url1">
        <el-col xs:="4" :sm="4" :md="4" :lg="4" :xl="4" class="fw-bold">
          TYPE
        </el-col>
        <el-col xs:="4" :sm="4" :md="4" :lg="4" :xl="4" class="fw-bold">
          Name
        </el-col>
        <el-col xs:="4" :sm="4" :md="4" :lg="4" :xl="4" class="fw-bold">
          Target/Value
        </el-col>
        <el-col xs:="4" :sm="4" :md="4" :lg="4" :xl="4" class="fw-bold">
          Domain Status
        </el-col>
        <el-col xs:="4" :sm="4" :md="4" :lg="4" :xl="4" class="fw-bold">
          SSL Status
        </el-col>
        <el-col xs:="4" :sm="4" :md="4" :lg="4" :xl="4" class="fw-bold">
          Action
        </el-col>
      </el-row>
      <el-row :gutter="20" v-if="data.config_custom_url1 && savedDomain === data.config_custom_url1" style="margin-top: 20px;"

      >
        <el-col xs:="4" :sm="4" :md="4" :lg="4" :xl="4">
          CNAME
        </el-col>
        <el-col xs:="4" :sm="4" :md="4" :lg="4" :xl="4">
          {{ data.config_custom_url1 }}
        </el-col>
        <el-col xs:="4" :sm="4" :md="4" :lg="4" :xl="4">
          {{ data.cname_config_url }}
        </el-col>
        <el-col xs:="4" :sm="4" :md="4" :lg="4" :xl="4">
          <span v-if="domainVerified.result !== 'active'" class="failed">Pending</span>
          <div v-if="domainVerified.config_custom_url1 && domainVerified.result === 'active'" class="success">Verified</div>
        </el-col>
        <el-col xs:="4" :sm="4" :md="4" :lg="4" :xl="4">
          <span v-if="domainVerified.ssl_result !== 'active'" class="failed">Pending</span>
          <div v-if="domainVerified.config_custom_url1 && domainVerified.ssl_result === 'active'" class="success">Verified</div>

        </el-col>
        <el-col xs:="4" :sm="4" :md="4" :lg="4" :xl="4">
          <el-button @click="verify" v-if="!domainVerified.config_custom_url1 || domainVerified.result !== 'active'|| domainVerified.ssl_result !== 'active'">Verify Now</el-button>
          <el-button @click="deleteDomain">Delete</el-button>
        </el-col>
      </el-row>
      <div v-if="data.config_custom_url1 && domainVerified.ssl_result && domainVerified.ssl_result !== 'active'">
        <el-row :gutter="20" style="padding-top: 20px;padding-bottom: 20px;" v-if="data.config_custom_url1">
          <el-col xs:="24" :sm="24" :md="24" :lg="24" :xl="24" class="align-left" style="text-align: left">
            <span style="font-weight: bold">SSL Certificate</span>:<br> To activate the SSL certificate, please create a "TXT" record within your domain's DNS settings as specified below. Afterward, proceed to click on "Verify Now." <br>Upon successful verification, your customized vanity domain will be fully functional over HTTPS
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col xs:="4" :sm="4" :md="4" :lg="4" :xl="4" class="fw-bold">
            TYPE
          </el-col>
          <el-col xs:="4" :sm="10" :md="10" :lg="10" :xl="10" class="fw-bold">
            Name
          </el-col>
          <el-col xs:="4" :sm="10" :md="10" :lg="10" :xl="10" class="fw-bold">
            Value
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col xs:="4" :sm="4" :md="4" :lg="4" :xl="4">
            TXT
          </el-col>
          <el-col xs:="4" :sm="10" :md="10" :lg="10" :xl="10">
            <div v-if="this.data.verificationResult">{{this.data.verificationResult.result.ssl.txt_name}}</div>
            <el-link @click="copyTxt()" v-if="!copiedTxt">Click to Copy</el-link>
            <div v-if="copiedTxt" class="success">Copied</div>
          </el-col>
          <el-col xs:="4" :sm="10" :md="10" :lg="10" :xl="10">
            <div v-if="this.data.verificationResult">{{this.data.verificationResult.result.ssl.txt_value}}</div>
            <el-link @click="copyValue()" v-if="!copiedValue">Click to Copy</el-link>
            <div v-if="copiedValue" class="success">Copied</div>
          </el-col>
        </el-row>
      </div>

      <!--        <el-row :gutter="20">-->
      <!--            <el-col :="24" :sm="24" :md="24" :lg="12" :xl="12">-->
      <!--                <el-form-item label="Website2 Custom URL">-->
      <!--                <el-input-->
      <!--                  v-model="data.config_custom_url2"-->
      <!--                  type="text"-->
      <!--                  autocomplete="off"-->
      <!--                />-->
      <!--              </el-form-item>-->
      <!--            </el-col>-->
      <!--            <el-col :="24" :sm="24" :md="24" :lg="12" :xl="12">-->
      <!--                <el-form-item label="Website3 Custom URL">-->
      <!--                <el-input-->
      <!--                  v-model="data.config_custom_url3"-->
      <!--                  type="text"-->
      <!--                  autocomplete="off"-->
      <!--                />-->
      <!--              </el-form-item>-->
      <!--            </el-col>-->
      <!--        </el-row>-->


    </div>
  </el-form>
  <el-row :gutter="20">
    <el-col
        :xs="24"
        :sm="24"
        :md="24"
        :lg="12"
        :xl="12"
    >

    </el-col>
    <el-col
        :xs="6"
        :sm="6"
        :md="6"
        :lg="12"
        :xl="12"
    >
      <div class="topsearch">
        <el-button
            type="success"
            @click="submitForm('ruleForm')"
        >
          Save
        </el-button>
      </div>
    </el-col>
  </el-row>
</template>
<script>
import UserService from "@/api/user.service";
import DnsService from "@/api/dns.service";

const CNAME_DOMAIN = process.env.VUE_APP_CNAME_DOMAIN

export default {
  data() {
    var checkMlsid = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('Please Enter the MLS®/Board user ID.'));
      } else {
        callback();
      }
    };
    return {
      copiedTxt: false,
      copiedValue: false,
      domainVerified: {config_custom_url1: null, result: null, ssl_result: null},
      showAlertAdded: false,
      labelPosition: 'left',
      domainsid: null,
      savedDomain: null,
      array_language: [{
        value: '1',
        label: 'English'
      }],
      data: {
        config_url: null,
        cname_config_url: null,
        verificationResult: null,
      },
      rules: {
        config_url: [
          {validator: checkMlsid, trigger: 'blur'}
        ],
      }
    };
  },
  mounted() {
    this.getdomains();
  },
  methods: {
    async submitForm(formName) {
      await this.$refs[formName].validate((valid) => {
        if (valid && this.domainsid) {
          return UserService.update(this.domainsid, this.data).then(response => {
            this.showAlertAdded = true;
            location.reload()
          });
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    handleFileUpload() {
    },
    getdomains() {
      return UserService.getUserBoard().then(response => {
        this.data = response.data.domains[0];
        this.domainsid = response.data.domains[0].id;
        this.data.config_url = response.data.domains[0].domain;
        this.data.cname_config_url = CNAME_DOMAIN
        this.savedDomain = this.data.config_custom_url1
        if(this.data.config_custom_url1) {
          this.confirmVerify()
        }
      });
    },
    deleteDomain() {
      return DnsService.delete(this.domainsid).then(response => {
        this.domainVerified.result = null
        this.domainVerified.ssl_result = null
        this.domainVerified.config_custom_url1 = null
        location.reload()
        // }
      });
    },
    confirmVerify() {
      DnsService.verify(this.domainsid, this.data).then(response => {
        const data = response.data.data[0]
        this.data.verificationResult = data
        this.domainVerified.result = data.result.status
        this.domainVerified.ssl_result = data.result.ssl.status
        this.domainVerified.config_custom_url1 = data.domain
      });
    },
    async verify() {
      // await this.submitForm('ruleForm')
      this.confirmVerify()
    },
    async copyTxt() {
      this.copiedTxt = false
      this.copiedValue = false
      try {
        await navigator.clipboard.writeText(this.data.verificationResult.result.ssl.txt_name)
      } catch (e) {
      }
      this.copiedTxt = true
    },
    async copyValue() {
      this.copiedTxt = false
      this.copiedValue = false
      try {
        await navigator.clipboard.writeText(this.data.verificationResult.result.ssl.txt_value)
      } catch (e) {
      }

      this.copiedValue = true
    },
  }
}
</script>
<style lang="scss" scoped>
.panel-title {
  border-bottom: 1px solid #9a83835c;
  padding-bottom: 10px;
  margin-bottom: 10px;
  text-align: left;
}

.panel {
  position: relative;
  width: 100%;
  margin-bottom: 10px;
  padding: 10px 17px;
  display: inline-block;
  background: #fff;
  border: 1px solid #E6E9ED;
  -webkit-column-break-inside: avoid;
  -moz-column-break-inside: avoid;
  opacity: 1;
  transition: all .2s ease;
}

.el-row {
  margin-bottom: 0px !important;
}

.el-form--label-top .el-form-item__label {
  float: left !important;
  font-weight: 500;
  font-size: 12px;
  padding: 0 0 0px 0;
}

.el-row {
  margin-bottom: 20px;

  &:last-child {
    margin-bottom: 0;
  }
}

.el-col {
  border-radius: 4px;
}

.heading {
  text-align: left;
  width: 100%;
  line-height: 2;
}

div .topsearch {
  float: right;
  display: inline-flex;
  padding: 15px 0px;
}

div .topsearch .el-input__inner {
  padding-right: 10px;
  height: 40px;
  width: 500px;
}

.el-select {
  width: 100%;
}

div .topsearch .el-input {
  padding-right: 10px;
}

.fw-bold {
  font-weight: bold;
}

.failed {
  margin-right: 20px;
  color: red;
}
.success {
  color: green;
}

</style>
