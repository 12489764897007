import BaseService from "./base.service";

class DnsService {
    verify(id, domains) {
        return BaseService.apiPostRequest('verify-dns/'+id, domains);
    }
    delete(id) {
        return BaseService.apiPostRequest('delete-dns/'+id, null);
    }
}

export default new DnsService();
